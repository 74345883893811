import { render, staticRenderFns } from "./vessel-side-index.vue?vue&type=template&id=64578080&"
import script from "./vessel-side-index.vue?vue&type=script&lang=js&"
export * from "./vessel-side-index.vue?vue&type=script&lang=js&"
import style0 from "./vessel-side-index.vue?vue&type=style&index=0&id=64578080&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports