<template>
    <CRow>
        <loading-overlay
            :active="isLoading"
            :is-full-page="true"
            loader="bars"
        />
        <CCol>
            <CRow>
                <CCol sm="12" lg="5">
                    <CRow>
                        <CCol sm="12">
                            <div class="form-group form-row" rol="group">
                                <label class="required col-form-label col-sm-12 col-lg-3 text-right">{{$t('label.crane')}}</label>
                                <div class="col-sm-12 col-lg-9 input-group Vselect">
                                    <v-select
                                        :class="CraneFiltre ? 'select-client--correct Vselect-adjust' : (verifySelect ? 'select-client--error Vselect-adjust' : 'Vselect-adjust')"
                                        v-model="$v.CraneFiltre.$model"
                                        :placeholder="$t('label.select')"
                                        :options="optionListGruas" 
                                        :reduce="option => option.value" 
                                        :getOptionLabel="option => option.label"
                                    >
                                        <template #no-options="{ }">
                                            {{$t('label.noResultsFound')}}
                                        </template>
                                        <template #option="{ Json, label }">
                                            {{ label }}<br />
                                            <cite>{{ Json.TpMachineName }} - {{ Json.MachineConditionName }} </cite>
                                        </template>
                                    </v-select>
                                    <div class="input-group-append">
                                        <div class="d-flex align-items-start">
                                            <CButton
                                                color="add"
                                                size="sm"
                                                class="rounded"
                                                @click.stop="activarModal"
                                                v-c-tooltip="{
                                                    content: $t('label.nueva')+' '+$t('label.machine'),
                                                    placement: 'top-end'
                                                }"
                                                v-if="!CraneFiltre"
                                            >
                                                <CIcon name="cil-playlist-add"/>
                                            </CButton>
                                            <CButton
                                                v-else
                                                color="watch"
                                                size="sm"
                                                class="rounded"
                                                @click.stop="activarModal"
                                                v-c-tooltip="{
                                                    content:$t('label.toView')+' '+$t('label.crane'),
                                                    placement: 'top-end'
                                                }"
                                            >
                                                <CIcon name="eye"/>
                                            </CButton>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 px-0" v-if="$v.CraneFiltre.$error && (CraneFiltre == '' && verifySelect == true)">
                                        <div class="text-invalid-feedback">
                                            {{$t('label.required')}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CCol>
                    </CRow>
                </CCol>
                <CCol sm="12" lg="4">
                    <CInput
                        :label="$t('label.alias')"
                        :horizontal="{ label: 'col-sm-12 col-lg-3 text-right required', input: 'col-sm-12 col-lg-9'}"
                        :placeholder="$t('label.alias')"
                        v-model="CraneAlias"
                        addLabelClasses="text-right required"
                        v-uppercase
                    />
                    </CCol>
                <CCol sm="12" lg="auto" class="d-flex align-items-start justify-content-end">
                    <CButton
                        class="mr-1"
                        color="add"
                        size="sm"
                        @click="Submit()"
                        :disabled="isSubmit"
                        v-c-tooltip="{
                            content:$t('label.add'),
                            placement: 'top-end'
                        }"
                    >
                        <CIcon name="checkAlt" />
                    </CButton>
                    <CButton
                        color="wipe"
                        size="sm"
                        v-c-tooltip="{content: $t('label.clearFields'), placement: 'top-end'}" 
                        @click="reset(true, false)"
                    >
                        <CIcon name="cil-brush-alt" />
                    </CButton>
                </CCol>
            </CRow>
        </CCol>
        <CCol sm="12">
            <dataTableExtended
                class="align-center-row-datatable"
                :items="formatedItems"
                :fields="fieldsGruas"
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                sorter
                :noItemsView="tableText.noItemsViewText"
                :items-per-page="5"
                pagination
                column-filter
                hover
            >
                <template #acciones="{item}">
                    <td class="text-center">
                        <CButton
                            color="edit"
                            size="sm"
                            class="mr-1"
                            v-c-tooltip="{
                                content: $t('label.edit')+' '+$t('label.crane'),
                                placement: 'top-end'
                            }"
                            @click="EditCrane(item)"
                        >
                            <CIcon name="pencil"/>
                        </CButton>
                        <CButton
                            color="wipe"
                            size="sm"
                            class="mr-1"
                            v-c-tooltip="{
                                content: $t('label.inactivateCranePlanification'),
                                placement: 'top-end'
                            }"
                            @click="RemoveCrane(item)"
                        >
                            <CIcon name="cil-trash" />
                        </CButton>
                    </td>
                </template>
            </dataTableExtended>
        </CCol>
        <appMachinesGruas
            @set-list="reset" 
            :Crane="true"
            :modalCraneMachine="modalCraneMachine"
        ></appMachinesGruas>
    </CRow>
</template>
<script>
    import { mapState } from 'vuex';
    import ModalMixin from '@/_mixins/modal';
    import { tableTextTranslatedHelpers, alertPropertiesHelpers, DateFormater } from '@/_helpers/funciones';
    import CraneValidations from '@/_validations/visitas/schedule/crane/CraneValidations';
    import UpperCase  from '@/_validations/uppercase-directive';
    import appMachinesGruas from '@/pages/planificacion-estiba/modals/app-machinesgruas-crud';

    //data
    function data() {
        return {
            modalCraneMachine: false,
            verifySelect: false,
            isLoading: false,
            isSubmit: false,
            CraneFiltre: '',
            CraneAlias: '',
            MachineCraneSelect: [],
            MachineCraneList: [],
        }
    }

    //methods
    function reset(filtre, table) {
        this.modalCraneMachine = false;
        if (filtre) {
            this.$v.$reset();
            this.CraneFiltre = '';
            this.CraneAlias = '';
            this.verifySelect = false;
        }
        if (table) {
            this.getGruas();
        }
    }

    async function getGruas() {
        this.isLoading = true;
        let requests = [
            this.$http.ejecutar("GET", "VisitMachineCraneFree-list", { VisitId: this.Visit }),
            this.$http.ejecutar("GET", "VisitMachineCrane-list", {VisitId: this.Visit}),
        ]
        Promise.all(requests)
            .then((responses) => {
                this.MachineCraneSelect = responses[0].data.data ? responses[0].data.data : [];
                this.MachineCraneList = responses[1].data.data ? responses[1].data.data : [];
                this.isLoading = false;
            }).catch((err) => {
                this.isLoading = false;
                this.$notify({
                    group: "container",
                    title: "¡Error!",
                    text: err,
                    type: "error",
                });
            })
    }
    function fieldsGruas(){
        return [
            { key: 'RowNumber', label: '#',_style: 'width:1%; text-align:center;', filter:false },
            { key: 'CraneAlias',label: this.$t('label.crane'), _style:'width:10%', _classes:"center-cell" },
            { key: 'MaxRadio',label: this.$t('label.maxRange')+'(MTS)', _style:'width:10%', _classes:"center-cell" },
            { key: 'MaxCapacity',label: this.$t('label.maxWeight')+'(TON)', _style:'width:10%', _classes:"center-cell" },
            { key: 'OperativeStatus', label: this.$t('label.status'), _style:'width:15%;', _classes:"center-cell" },
            { key: 'VisitCraneStatusDs',label: this.$t('label.condition'), _style:'width:15%', _classes:"center-cell" },
            { key: 'DateFrom',label: this.$t('label.FirstMovement'), _style:'width:15%', _classes:"center-cell" },
            { key: 'DateTo',label: this.$t('label.LastMovement'), _style:'width:15%', _classes:"center-cell" },
            { 
                key: 'acciones', 
                label: '', 
                _style: 'min-width: 90px; width: 1%', 
                sorter: false, 
                filter: false
            }
        ]
    }
    
    function Submit(){
        try{
            this.isLoading = true;
            this.isSubmit = true;
            this.verifySelect = true;
            this.$v.$touch();
            if (this.$v.$error) {
              throw this.$t('label.select')+' '+this.$t('label.crane');
            }
            let VisitMachineJson = {};
            let link = '';
            let json = '';

            link = "VisitMachineCrane-insert";
            json = "VisitMachineJson";

            VisitMachineJson = {
                VisitId: this.Visit, 
                MachineId: this.CraneFiltre,
                CraneAlias: this.CraneAlias,
                FgOperational: 1,
            }
            this.$http.ejecutar('POST', link, VisitMachineJson, { root: json })
                .then(response => {
                    if(response.data.status === 200){
                        let information = response.data.data;
                        if(information[0].id === ""){
                            this.$notify({
                                group: 'container',
                                type: 'error',
                                title: 'ERROR',
                                text: information[0].Response,
                            });
                        }else{
                            this.$notify({
                                group: 'container',
                                title: '¡'+this.$t('label.success')+'!',
                                text: information[0].Response,
                                type: "success"
                            });
                            this.reset(true, true);
                            this.isSubmit = false; 
                        }
                    }
                }).catch( err => {
                    this.isSubmit = false;
                    this.isLoading = false;
                    this.$notify({
                        group: 'container',
                        type: 'error',
                        title: 'ERROR',
                        text: err,
                    });
                });
        }catch(e){
            this.isSubmit = false;
            this.isLoading = false;
            this.$notify({
                group: 'container',
                type: 'error',
                ignoreDuplicates:false,
                title: 'ERROR',
                text: e,
            });
        }
    }
    function activarModal(){
        this.$store.state.planificacionestiba.idState = this.CraneFiltre?this.CraneFiltre:0;
        this.modalCraneMachine = true;
    }
    function EditCrane(item){
        this.$store.state.planificacionestiba.idState = item.MachineId;
        this.modalCraneMachine = true;
    }
    function RemoveCrane(item){
        let nombre = '';
        let VisitMachineJson = {};
        let link = '';
        let json = '';

        nombre = item.CraneName;
        link = "VisitMachineCrane-insert";
        json = "VisitMachineJson";

        VisitMachineJson = {
            VisitCraneId: item.VisitCraneId, 
            VisitId: this.Visit, 
            MachineId: item.MachineId,
            CraneAlias: item.CraneAlias,
            FgOperational: item.FgOperational ? 1 : 0,
            Status:0
        }
        
        if(nombre !== ''){
            this.$swal.fire(
                alertPropertiesHelpers(this, {
                    text: `${this.$t('label.changeStatusQuestion')}?`,
                })
               ).then((result) => {
                if (result.isConfirmed) {
                    this.isLoading = true;
                    this.$http.ejecutar('POST', link, VisitMachineJson, { root: json })
                    .then(response => {
                        if(response.data.status === 200){
                            const information = response.data.data;
                            if(information[0].id === ""){
                                this.$notify({
                                    group: 'container',
                                    type: 'error',
                                    title: 'ERROR',
                                    text: information[0].Response,
                                });
                                this.isLoading = false;
                            }else{
                                this.$notify({
                                    group: 'container',
                                    title: '¡'+this.$t('label.success')+'!',
                                    text: information[0].Response,
                                    type: "success"
                                });
                                this.reset(true, true);
                            }   
                        }else{
                            this.isLoading = false;
                        }
                    }).catch( err => {
                        this.$notify({
                            group: 'container',
                            type: 'error',
                            title: 'ERROR',
                            text: err,
                        });
                        this.isLoading = false;
                    });
                }
            })
        }
        
    }
    function colorBadgeCrane(item){
        if(item.FgOperational && item.TotalHour === '00:00'){
            return 'secondary';
        }else{
            return '';
        }
        
    }
    //computed
    function CraneTitle(index) {
        if (index === 0) {
            return this.$t('label.OnBoard');
        } else {
            return this.$t('label.VesselSide');
        }
    }
    function formatedItems() {
        let _lang = this.$i18n.locale;
        return this.MachineCraneList.map((item, index) => {
            return {
                ...item,
                RowNumber: index+1,
                OperativeStatus: _lang=='en' ? item.OperativeStatusEn : item.OperativeStatusEs,
                VisitCraneStatusDs: _lang=='en' ? item.VisitCraneStatusDsEn : item.VisitCraneStatusDsEs,
                DateFrom: item.DateFrom ? DateFormater.formatDateTimeWithSlash(item.DateFrom) : 'N/A',
                DateTo: item.DateTo ? DateFormater.formatDateTimeWithSlash(item.DateTo) : 'N/A',
            }
        });
    }
    function optionListGruas(){
        if(this.MachineCraneSelect.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            let chart = [{
                value: '', 
                label: this.$t('label.select'),
                Json: {
                    TpMachineName: '',
                    MachineConditionName: '',
                },
            }]
            this.MachineCraneSelect.map(function(e){
                if(e.FgActMachine){
                    chart.push({
                        value: e.MachineId, 
                        label: e.MachineName,
                        Json: {
                            TpMachineName: e.TpMachineName,
                            MachineConditionName: e.MachineConditionName
                        },
                    })
                }
            })
            return chart;
        }
    }

    function tableText(){
        return tableTextTranslatedHelpers(this);
    }
    
    export default {
        name: 'vessel-side-index',
        data,
        props:{
            tabIndexGruas: null,
        },
        mixins: [ModalMixin],
        validations: CraneValidations,
        directives: UpperCase,
        methods: {
            CraneTitle,
            reset,
            EditCrane,
            activarModal,
            Submit,
            getGruas,
            colorBadgeCrane,
            RemoveCrane
        },
        computed: {
            optionListGruas,
            fieldsGruas,
            formatedItems,
            tableText,
            ...mapState({
                Visit: state => state.visitas.VisitId,
            })
        },
        components: {
            appMachinesGruas,
        },
        watch:{
            tabIndexGruas:function (val){
                if (val == 1 && this.Visit != '') {
                    this.reset(true, true);
                }
            }
        }
    } 
</script>
<style lang="scss">
    .Vselect .Vselect-adjust{
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        min-width: 0;
        margin-bottom: 0;
    }
</style>