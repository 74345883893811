var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',[_c('loading-overlay',{attrs:{"active":_vm.isLoading,"is-full-page":true,"loader":"bars"}}),_c('CCol',[_c('CRow',[_c('CCol',{attrs:{"sm":"12","lg":"5"}},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"form-group form-row",attrs:{"rol":"group"}},[_c('label',{staticClass:"required col-form-label col-sm-12 col-lg-3 text-right"},[_vm._v(_vm._s(_vm.$t('label.crane')))]),_c('div',{staticClass:"col-sm-12 col-lg-9 input-group Vselect"},[_c('v-select',{class:_vm.CraneFiltre ? 'select-client--correct Vselect-adjust' : (_vm.verifySelect ? 'select-client--error Vselect-adjust' : 'Vselect-adjust'),attrs:{"placeholder":_vm.$t('label.select'),"options":_vm.optionListGruas,"reduce":option => option.value,"getOptionLabel":option => option.label},scopedSlots:_vm._u([{key:"no-options",fn:function({ }){return [_vm._v(" "+_vm._s(_vm.$t('label.noResultsFound'))+" ")]}},{key:"option",fn:function({ Json, label }){return [_vm._v(" "+_vm._s(label)),_c('br'),_c('cite',[_vm._v(_vm._s(Json.TpMachineName)+" - "+_vm._s(Json.MachineConditionName)+" ")])]}}]),model:{value:(_vm.$v.CraneFiltre.$model),callback:function ($$v) {_vm.$set(_vm.$v.CraneFiltre, "$model", $$v)},expression:"$v.CraneFiltre.$model"}}),_c('div',{staticClass:"input-group-append"},[_c('div',{staticClass:"d-flex align-items-start"},[(!_vm.CraneFiltre)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                                content: _vm.$t('label.nueva')+' '+_vm.$t('label.machine'),
                                                placement: 'top-end'
                                            }),expression:"{\n                                                content: $t('label.nueva')+' '+$t('label.machine'),\n                                                placement: 'top-end'\n                                            }"}],staticClass:"rounded",attrs:{"color":"add","size":"sm"},on:{"click":function($event){$event.stopPropagation();return _vm.activarModal.apply(null, arguments)}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}})],1):_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                                content:_vm.$t('label.toView')+' '+_vm.$t('label.crane'),
                                                placement: 'top-end'
                                            }),expression:"{\n                                                content:$t('label.toView')+' '+$t('label.crane'),\n                                                placement: 'top-end'\n                                            }"}],staticClass:"rounded",attrs:{"color":"watch","size":"sm"},on:{"click":function($event){$event.stopPropagation();return _vm.activarModal.apply(null, arguments)}}},[_c('CIcon',{attrs:{"name":"eye"}})],1)],1)]),(_vm.$v.CraneFiltre.$error && (_vm.CraneFiltre == '' && _vm.verifySelect == true))?_c('div',{staticClass:"col-sm-12 px-0"},[_c('div',{staticClass:"text-invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('label.required'))+" ")])]):_vm._e()],1)])])],1)],1),_c('CCol',{attrs:{"sm":"12","lg":"4"}},[_c('CInput',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"label":_vm.$t('label.alias'),"horizontal":{ label: 'col-sm-12 col-lg-3 text-right required', input: 'col-sm-12 col-lg-9'},"placeholder":_vm.$t('label.alias'),"addLabelClasses":"text-right required"},model:{value:(_vm.CraneAlias),callback:function ($$v) {_vm.CraneAlias=$$v},expression:"CraneAlias"}})],1),_c('CCol',{staticClass:"d-flex align-items-start justify-content-end",attrs:{"sm":"12","lg":"auto"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                        content:_vm.$t('label.add'),
                        placement: 'top-end'
                    }),expression:"{\n                        content:$t('label.add'),\n                        placement: 'top-end'\n                    }"}],staticClass:"mr-1",attrs:{"color":"add","size":"sm","disabled":_vm.isSubmit},on:{"click":function($event){return _vm.Submit()}}},[_c('CIcon',{attrs:{"name":"checkAlt"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({content: _vm.$t('label.clearFields'), placement: 'top-end'}),expression:"{content: $t('label.clearFields'), placement: 'top-end'}"}],attrs:{"color":"wipe","size":"sm"},on:{"click":function($event){return _vm.reset(true, false)}}},[_c('CIcon',{attrs:{"name":"cil-brush-alt"}})],1)],1)],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.formatedItems,"fields":_vm.fieldsGruas,"table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"sorter":"","noItemsView":_vm.tableText.noItemsViewText,"items-per-page":5,"pagination":"","column-filter":"","hover":""},scopedSlots:_vm._u([{key:"acciones",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                            content: _vm.$t('label.edit')+' '+_vm.$t('label.crane'),
                            placement: 'top-end'
                        }),expression:"{\n                            content: $t('label.edit')+' '+$t('label.crane'),\n                            placement: 'top-end'\n                        }"}],staticClass:"mr-1",attrs:{"color":"edit","size":"sm"},on:{"click":function($event){return _vm.EditCrane(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                            content: _vm.$t('label.inactivateCranePlanification'),
                            placement: 'top-end'
                        }),expression:"{\n                            content: $t('label.inactivateCranePlanification'),\n                            placement: 'top-end'\n                        }"}],staticClass:"mr-1",attrs:{"color":"wipe","size":"sm"},on:{"click":function($event){return _vm.RemoveCrane(item)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1)],1)]}}])})],1),_c('appMachinesGruas',{attrs:{"Crane":true,"modalCraneMachine":_vm.modalCraneMachine},on:{"set-list":_vm.reset}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }